<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Moving Items</h3>

	<p>A System Admin, System Editor, Framework Admin, and Framework Editor have the ability and permissions to edit and move items within a framework.</p>

	<p>Selecting the edit option to MOVE ITEMS will reveal <strong>drag and drop icons</strong> for each item in the framework.</p>

	<p>Choose the EDIT <nobr><v-icon small>fas fa-edit</v-icon></nobr> icon at the top of the framework. Click on any item in the framework and select the edit icon. Select <strong>MOVE ITEMS</strong> from the menu of options displayed. </p>

	<p>The following pop up window will appear:
		<br>
		<img srcset="/docimages/move_items.png" class="k-help-img block">
	</p>

	<p><strong>NOTE:</strong> It helps to select the <strong>Parent Item</strong> that you would like to nest other <strong>Child Items</strong> under prior to selecting the edit and move icons. Remember to choose the <strong><nobr><v-icon small>fa-solid fa-circle-dot</v-icon></nobr> CHILD ITEM icon</strong> for the receiving items.</p>

	<p>Once you’ve selected the <strong><nobr><v-icon small>fa-solid fa-circle-dot</v-icon></nobr> CHILD ITEM icon</strong>, a message will appear below it <span style="color: #757575;">Drag here to add children to this item</span></p>

	<p>Select <strong>DONE MOVING</strong> when finished.</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	
	